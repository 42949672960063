<template>
<div id="pageOrder">
    <v-container grid-list-xl fluid>
        <v-layout row wrap class="page-header-right">
            <!-- Summary info -->
            <v-flex lg6 pa-0 pl-3 pt-3>
                <v-btn
                    :color="order.Status | order_status_color"
                    width="160px"
                    height="36px"
                    small
                    dark
                    depressed
                    outlined
                  >{{ order.Status | order_status}}</v-btn>
            </v-flex>
            <!-- Action buttons -->
            <v-flex lg6 pa-0 pr-6 pt-6>
                <v-layout row wrap justify-end>
                    <v-btn @click="removeOrder(order
                    )" v-if="adminRole && (order.Status == statusEnum.cho_bao_gia || order.Status == statusEnum.cho_dat_coc)" color="black" dark>
                        Xóa đơn
                    </v-btn>
                    <v-btn @click="WaitPayOrder" v-if="order.Status == statusEnum.cho_bao_gia && (purchaseRole || adminRole)" color="red" dark>
                        Chờ đặt cọc
                    </v-btn>
                    <v-btn @click="ComplainOrder" v-if="order.Status == statusEnum.da_nhap_kho_phat" color="red" dark>
                        Khiếu nại shop
                    </v-btn>
                    <v-btn @click="ComplainOrder(0)" v-if="order.Status == statusEnum.khieu_nai_shop" color="red" dark>
                        Hủy khiếu nại shop
                    </v-btn>
                    <v-btn @click="CancelOrder" v-if="(orderReady && ((orderRole & 2048)) || (adminRole && !canReorder))" color="black" dark>
                        Hủy đơn
                    </v-btn>
                    <v-btn @click="ReOrder" v-if="canReorder && ((orderRole & 2048) || adminRole)">
                        Lên đơn
                    </v-btn>
                     <v-btn @click="DepositOrder" v-if="order.Status == statusEnum.cho_dat_coc && adminRole" color="red" dark>
                        Đặt cọc
                    </v-btn>
                    <order-flag-menu :orderID="orderId"></order-flag-menu>
                    <!-- <order-widget-menu></order-widget-menu> -->
                </v-layout>
            </v-flex>
            <!-- Khach hang -->
            <v-flex v-if="orderWidgets.customer" v-bind:class="{ 'lg12': !orderWidgets.chat && orderWidgets.customer, 'lg6': orderWidgets.customer && orderWidgets.chat}">
                <v-widget :title="'#'+orderId">
                    <div slot="widget-header-action">
                        <h4 class="pl-2 pr-3 font-weight-bold">{{order.CreatedDate | display_date('DD-MM-YYYY HH:mm:ss') | capitalize}}</h4>
                    </div>
                    <div slot="widget-content" class="customer-info-box">
                        <v-card-text>
                            <v-layout row wrap pt-1>
                                <v-flex lg4 sm4>
                                    <v-card-title class="pa-0">Người đặt hàng</v-card-title>
                                </v-flex>
                                <v-flex lg8 sm8 pa-0>
                                    <div class="mt-4 account-toggle font-weight-bold text-red" @click="openCustomerDetail">{{customer.Username}} <span v-if="(orderRole & 1)" class="icon" :class="{'open':showCustomerDetail}"><i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-down theme--light"></i></span></div>
                                </v-flex>
                            </v-layout>
                            <v-card class="mx-auto" outlined v-if="showCustomerDetail && (orderRole & 1)">
                                <v-list-item three-line>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-2"><a href="javascript:void(0)" class="font-weight-bold text-red" @click="gotoCustomerDetail(customer.ID)">{{customer.Username}}</a></v-list-item-title>
                                    <v-list-item-subtitle class="mb-1">Họ tên: {{customer.FirstName + ' ' + customer.LastName}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mb-1">Điện thoại: {{`${customer.PhoneNumber}`}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mb-1">Địa chỉ: {{customer.Address}}</v-list-item-subtitle>
                                </v-list-item-content>
                                </v-list-item>
                            </v-card>
                            <v-layout row wrap pt-2 v-if="!(orderRole & 1) || !showCustomerDetail">
                                <v-flex lg12 sm12 style="height: 105px">
                                    
                                </v-flex>
                            </v-layout>
                            <template v-if="(orderRole & 2)">
                                <v-layout row wrap pt-1>
                                    <v-flex lg4 sm4>
                                        <v-card-title class="pa-0">Tiếng Trung</v-card-title>
                                    </v-flex>
                                    <v-flex lg8 sm8 pa-0>
                                        <v-select class="pa-0" :items="cs_staffs" v-model="orderEditable.CustomerServiceID" item-text="FullName" item-value="ID" :disabled="orderReadonly || !adminRole"></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap pt-2>
                                    <v-flex lg4 sm4>
                                        <v-card-title class="pa-0">CSKH</v-card-title>
                                    </v-flex>
                                    <v-flex lg8 sm8 pa-0>
                                        <v-select class="pa-0" :items="p_staffs" v-model="orderEditable.BuyerID" item-text="FullName" item-value="ID" :disabled="orderReadonly || !adminRole"></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap pt-2>
                                    <v-flex lg4 sm4>
                                        <v-card-title class="pa-0">Kho gửi</v-card-title>
                                    </v-flex>
                                    <v-flex lg8 sm8 pa-0>
                                        <v-select :disabled="orderReadonly || !adminRole" class="pa-0" :items="chinaStores" v-model="orderEditable.ChinaStoreID" item-text="Name" item-value="ID"></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap pt-2>
                                    <v-flex lg4 sm4>
                                        <v-card-title class="pa-0">Kho nhận</v-card-title>
                                    </v-flex>
                                    <v-flex lg8 sm8 pa-0>
                                        <v-select :disabled="orderReadonly || !adminRole" class="pa-0" :items="warehouses" v-model="orderEditable.ReceivePlaceID" item-text="Name" item-value="ID"></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap pt-2 v-if="order.WeChatId && order.WeChatId != ''">
                                    <v-flex lg4 md4>
                                        <v-card-title class="pa-0 font-weight-bold text-red">WeChat ID</v-card-title>
                                    </v-flex>
                                    <v-flex lg8 sm8 pa-0>
                                        <v-text-field v-model="orderEditable.WeChatId" label="WeChat ID" ></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <!-- <v-layout row wrap pt-2>
                                    <v-flex lg4 md4>
                                        <v-card-title class="pa-0 font-weight-bold text-red">Trạng thái đơn hàng</v-card-title>
                                    </v-flex>
                                    <v-flex lg8 sm8 pa-0>
                                        <v-select class="pa-0 font-weight-bold text-red" :items="orderStatus" v-model="order.Status" item-text="name" item-value="value" disabled></v-select>
                                    </v-flex>
                                </v-layout> -->
                            </template>
                        </v-card-text>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Danh gia don hang -->
            <v-flex v-if="orderWidgets.chat && (orderRole & 16)" v-bind:class="{ 'lg12': !orderWidgets.customer && orderWidgets.chat, 'lg6': orderWidgets.customer && orderWidgets.chat}">
                <v-widget title="Tin nhắn">
                    <!-- <div slot="widget-header-action">
                        <template v-for="item in allFlags" >
                            <v-tooltip top v-if="item.Checked" :key="item.ID">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" medium :color="item.FlagColor" class="mr-3">flag</v-icon>
                                </template>
                                <span>{{item.FlagName}}</span>
                            </v-tooltip>
                        </template>
                    </div> -->
                    <div slot="widget-content">
                        <message-box :orderId="orderId" :orderType="1"></message-box>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Danh sách sản phẩm -->
            <v-flex sm12 md12 lg12 v-if="orderWidgets.product && (orderRole & 4)">
                <v-dialog v-model="dialogProduct" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">Cập nhật sản phẩm</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field type="number" v-model="editedPro.quantity" label="Số lượng"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field type="number" step=".1" v-model="editedPro.price_promotion" label="Đơn giá"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-textarea rows="3" v-model="editedPro.comment" label="Ghi chú" class="input-group--focused"></v-textarea>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" dark @click="closeProductDialog">Đóng</v-btn>
                            <v-btn color="red darken-1" dark @click="saveProduct">Lưu lại</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogAppendProduct" max-width="1000px">
                    <v-card>
                        <v-card-title>
                        <span class="title">Thêm link sản phẩm</span>
                        </v-card-title>
                        <v-card-text>
                        <v-container grid-list-md pa-0>
                            <v-layout row wrap>
                            <v-flex lg12 md12 sm12 xs12>
                                <p class="body-2 font-weight-bold text-red">Danh sách sản phẩm</p>
                                <v-list>
                                <v-list-item v-for="(item, index) in newProducts" :key="item.ID">
                                    <v-list-item-content>
                                    <v-flex lg2 md2 sm12>
                                    <v-text-field label="Link sản phẩm" v-model="item.Link"></v-text-field>
                                    </v-flex>
                                    <v-flex lg2 md2 sm12>
                                    <!-- <v-text-field label="Link ảnh" v-model="item.Image"></v-text-field> -->
                                    <img style="max-width: 56px; max-height: 56px" v-if="!hideImage" v-bind:src="`${getImages(index)}`" ref="imageFile"/>
                                    </v-flex>
                                    <v-flex lg4 md4 sm12>
                                    <v-text-field label="Thuộc tính" v-model="item.Property"></v-text-field>
                                    </v-flex>
                                    <v-flex lg2 md2 sm12>
                                    <h-currency-input label="Đơn giá (¥)" :decimal="1" v-model="item.Price"></h-currency-input>
                                    </v-flex>
                                    <v-flex lg2 md2 sm12>
                                    <h-currency-input label="Số lượng" :decimal="0" v-model="item.Quantity"></h-currency-input>
                                    </v-flex>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                    <v-btn icon @click="newProducts.splice(index, 1);">
                                        <v-icon>fa-trash-alt</v-icon>
                                    </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                </v-list>
                                <v-layout row wrap>
                                <v-flex lg4 md4 sm12>
                                    <v-text-field label="Link sản phẩm" v-model="newProduct.Link"></v-text-field>
                                </v-flex>
                                <v-flex lg2 md2 sm12>
                                    <div class="col-md-12 box-right fb flex-c text-red form-group" style="padding: 0;">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <img style="max-width: 56px; max-height: 56px" v-if="!hideImage" v-bind:src="newProduct.Image" ref="imageFile"/>
                                            </div>
                                            <div class="col-md-8">
                                                <input type="file" ref="fileInput" style="display: none" @change="previewImage" accept="image/*" > 
                                                <button @click="chooseImage(newProduct)" style="cursor: point">Tải ảnh</button>
                                            </div>
                                        </div>
                                    </div>
                                </v-flex>
                                <v-flex lg3 md3 sm12>
                                    <v-text-field label="Thuộc tính" v-model="newProduct.Property"></v-text-field>
                                </v-flex>
                                <v-flex lg1 md1 sm12>
                                    <h-currency-input label="Đơn giá (¥)" :decimal="1" v-model="newProduct.Price"></h-currency-input>
                                </v-flex>
                                <v-flex lg1 md1 sm12>
                                    <h-currency-input label="Số lượng" :decimal="0" v-model="newProduct.Quantity"></h-currency-input>
                                </v-flex>
                                <v-flex lg1 md1 sm12>
                                    <v-btn icon large @click="appendProduct"><v-icon color="#dc3545">far fa-plus-square</v-icon></v-btn>
                                </v-flex>
                                </v-layout>
                            </v-flex>
                            </v-layout>
                        </v-container>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1 white--text" @click="dialogAppendProduct = false">Đóng</v-btn>
                        <v-btn color="red darken-1 white--text" @click="createNewOrder">OK</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-widget title="Danh sách sản phẩm">
                    <div slot="widget-header-action">
                        <v-btn @click="appendProductShow" v-if="(productEditable && (orderEditRole & 4) && !orderReadonly) || adminRole">
                            <v-icon color="red darken-3">fa-plus</v-icon> Thêm link
                        </v-btn>
                    </div>
                    <div slot="widget-content">
                        <v-data-table  :headers="productHeader" :items="products" :mobile-breakpoint="100" hide-default-footer disable-pagination>
                            <template  v-slot:item="{ item, index }">
                                <tr @click="productSelectedIndex = index" @click.middle="productSelectedIndex = index" :class="productSelectedIndex === index ? 'product-highlight-row' : ''">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <div v-on:click="gotoOfferLink(item.item_id, order.Site, item.link_origin)"><a href="#">{{ item.ID }}</a></div>
                                    </td>
                                    <td>
                                        <img class="image-product" :src="item.image_origin && item.image_origin.includes('firebase') ? item.image_origin : decodeURIComponent(item.image_origin)" alt />
                                    </td>
                                    <td>{{ item.property }}</td>
                                    <td class="text-end" v-if="(productEditable && (orderEditRole & 4) && !orderReadonly) || adminRole">
                                        <v-edit-dialog
                                        :return-value.sync="item.quantity"
                                        large
                                        lazy
                                        persistent
                                        @save="saveProduct({'ID': item.ID, 'quantity': item.quantity})"
                                        >
                                        <div>{{ item.quantity | currency}}</div>
                                        <template v-slot:input>
                                            <h-currency-input
                                            v-model="item.quantity"
                                            :decimal="0"
                                            label="Số lượng"
                                            autofocus
                                            ></h-currency-input>
                                        </template>
                                        </v-edit-dialog>
                                    </td>
                                    <td class="text-end" v-else>{{ item.quantity | currency }}</td>
                                    <td class="text-end" v-if="(productEditable && (orderEditRole & 4) && !orderReadonly) || adminRole">
                                        <v-edit-dialog
                                        :return-value.sync="item.price_promotion"
                                        large
                                        lazy
                                        persistent
                                        @save="saveProduct({'ID': item.ID, 'price_promotion': item.price_promotion})"
                                        >
                                        <div>{{ item.price_promotion | currency('¥', 2)}}</div>
                                        <template v-slot:input>
                                            <h-currency-input
                                            v-model="item.price_promotion"
                                            :decimal="2"
                                            label="Giá"
                                            autofocus
                                            ></h-currency-input>
                                        </template>
                                        </v-edit-dialog>
                                    </td>
                                    <td class="text-end" v-else>{{ item.price_promotion | currency('¥', 2) }}</td>
                                    <td class="text-end">{{ item.quantity*item.price_promotion | currency('¥', 2) }}</td>
                                    <td class="text-center">
                                        <!-- <v-icon  color="red lighten-2" small @click="gotoOfferLink(item.item_id, order.Site)">local_offer</v-icon> -->
                                        <!-- <v-btn text icon color="red lighten-2" @click="editProduct(item)" v-if="(productEditable && (orderEditRole & 4) && !orderReadonly) || adminRole">
                                            <v-icon small>fa-edit</v-icon>
                                        </v-btn> -->
                                        <v-btn text icon color="red lighten-2" @click="removeProduct(item)" v-if="(productEditable && (orderEditRole & 4) && !orderReadonly) || adminRole">
                                            <v-icon small>fa-trash-alt</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="8" class="text-red">
                                        <v-edit-dialog
                                        :return-value.sync="item.comment"
                                        large
                                        lazy
                                        persistent
                                        @save="saveProduct({'ID': item.ID, 'comment': item.comment})"
                                        >
                                        <div>Ghi chú: {{ item.comment }}</div>
                                        <template v-slot:input>
                                            <v-text-field
                                            v-model="item.comment"
                                            label="Ghi chú"
                                            autofocus
                                            ></v-text-field>
                                        </template>
                                        </v-edit-dialog>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:body.append>
                                <tr>
                                    <td colspan="4" class="font-weight-bold">Tổng tiền hàng</td>
                                    <td class="text-end font-weight-bold">{{totalProduct | currency}}</td>
                                    <td></td>
                                    <td class="text-end font-weight-bold">{{order.AmountCNY | currency('CNY', 2)}}</td>
                                    <td></td>
                                </tr>
                                <tr v-if="(orderRole & 256) || complainRole || csRole || internalRole">
                                    <td colspan="4" class="font-weight-bold">Phí ship nội địa</td>
                                    <td class="text-end font-weight-bold"></td>
                                    <td></td>
                                    <td class="text-end font-weight-bold">
                                        <h-currency-input :disabled="orderReadonly || !productEditable || !(orderEditRole & 64)" class="fee-cn-footer" v-model="feeShipCNY" label="¥" @press_enter="manualUpdateDetail" @on_blur="manualUpdateDetail"></h-currency-input>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="font-weight-bold">Tổng cộng</td>
                                    <td class="text-end font-weight-bold"></td>
                                    <td></td>
                                    <td class="text-end font-weight-bold">{{order.AmountCNY + orderEditable.FeeShipCN | currency('CNY', 2)}}</td>
                                    <td></td>
                                </tr>
                                <tr v-if="(orderRole & 256) || realAmountRole">
                                    <td colspan="4" class="font-weight-bold">Tiền mua thật</td>
                                    <td class="text-end font-weight-bold"></td>
                                    <td></td>
                                    <td class="text-end font-weight-bold">
                                        <h-currency-input disabled class="fee-cn-footer" v-model="totalRealAmountCNY" label="¥" @press_enter="manualUpdateDetail" @on_blur="manualUpdateDetail"></h-currency-input>
                                    </td>
                                    <td></td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Danh sách hình ảnh khiếu nại shop -->
            <v-flex sm12 md12 lg12 v-if="order.Status == statusEnum.khieu_nai_shop || order.Status == statusEnum.da_nhap_kho_phat">
            <v-widget title="Hình ảnh khiếu nại shop">
                <template slot="widget-content">
                    <v-layout row wrap>
                        <v-flex lg12 md12 sm12 xs12 pb-0>
                            <p>Hình ảnh</p>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 pt-0>
                            <gallery :images="images" :index="imgIndex" @close="imgIndex = null"></gallery>
                            <div 
                                class="image image-container"
                                v-for="(image, imageIndex) in images"
                                :key="imageIndex"
                                :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"
                            >
                            <v-btn color="red" fab dark small class="image-zoom" @click="imgIndex = imageIndex"><v-icon small>fa-search-plus</v-icon></v-btn>
                            <v-btn color="red" fab dark small class="image-x" @click="removeComplainImage(imageIndex)"><v-icon small>fa-trash-alt</v-icon></v-btn></div>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                        <v-flex lg6 sm6>
                            <v-text-field
                                label="Chọn ảnh từ máy tính"
                                @click="pickFile"
                                v-model="imageName"
                                prepend-icon="attach_file"
                            ></v-text-field>
                            <input
                                type="file"
                                style="display: none"
                                ref="image"
                                accept="image/*"
                                @change="onFilePicked"
                            >
                        </v-flex>
                        <v-flex lg3 sm3>
                            <v-btn color="primary" @click="uploadImage">Tải ảnh</v-btn>
                        </v-flex>
                    </v-layout>
                </template>
                </v-widget>
            </v-flex>
            <!-- Ma van don & don hang CN -->
            <v-flex sm12 md12 lg12 v-if="orderWidgets.transaction && (orderRole & 8)">
                <v-widget title="Mã bill">
                    <div slot="widget-content">
                        <order-transaction :locked="orderLocked" :disabled="confirmPrice || orderNotDeposit || orderReadonly || !(orderEditRole & 8)" :orderId="orderId" :orderStatus="order.Status" :siteName="order.Site ? order.Site.toString() : ''" :customerID="customer.ID" :customerName="customer.Username"></order-transaction>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Chi phi don hang -->
            <v-flex sm12 md12 lg12 v-if="orderWidgets.paymentArea && (orderRole & 256)">
                <v-widget title="Chi phí đơn hàng">
                    <div slot="widget-content">
                        <v-layout row wrap>
                            <v-flex lg12 sm12 pa-0 ma-0 pr-2>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pa-0 pt-6 pl-6>
                                        <v-checkbox label="Dịch vụ đóng gỗ" v-model="orderEditable.IsFragileGoods" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole || paymentRole)"></v-checkbox>
                                    </v-flex>
                                    <v-flex lg3 sm3 pa-0 pt-6>
                                        <v-checkbox label="Dịch vụ kiểm hàng (gói A)" v-model="isCheckPackageA" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole || paymentRole)"></v-checkbox>
                                    </v-flex>
                                    <v-flex lg3 sm3 pa-0 pt-6>
                                        <v-checkbox label="Dịch vụ kiểm hàng (gói B)" v-model="isCheckPackageB" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole || paymentRole)"></v-checkbox>
                                    </v-flex>
                                    <v-flex lg3 sm3 pb-0>
                                        <v-select label="Bảo hiểm hàng" v-model="orderEditable.GuaranteeType" :items="guarantees" item-text="PackageName" item-value="ID" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole || paymentRole)"></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pb-0>
                                        <v-card-title>Tỷ giá</v-card-title>
                                    </v-flex>
                                    <v-flex lg5 sm5 pb-0 pl-2>
                                        <h-currency-input :autoEmit="false" v-model="orderEditable.ExchangeRate" label="Tỷ giá tệ (₫)" :decimal="0" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole)"></h-currency-input>
                                    </v-flex>
                                    <v-flex lg4 sm4 pb-0 pl-2>
                                        <h-currency-input :autoEmit="false" v-model="orderEditable.ExchangeRevenue" label="Lãi tỷ giá (₫)" :decimal="0" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole)"></h-currency-input>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pb-0 pt-0>
                                        <v-card-title>Tổng giá</v-card-title>
                                    </v-flex>
                                    <v-flex lg9 sm9 pb-0 pt-0 pl-2>
                                        <v-card-title class="pl-0 font-weight-bold subheading">{{order.AmountCNY | currency('CNY', 2)}} ( {{order.AmountVND | currency}} )</v-card-title>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-title>Tổng số tiền mua thật</v-card-title>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.TotalRealAmountCNY" label="¥" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="totalRealAmount" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-title>Phí ship nội địa</v-card-title>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FeeShipCN" label="¥" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FeeShipCNVND" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-title>Phí mua hàng (CK: {{orderEditable.FeeBuyDiscount | currency}}%)</v-card-title>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input :autoEmit="false" v-model="orderEditable.FeeBuy" :decimal="1" label="%" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia) && !(adminRole && order.Status != statusEnum.hoan_thanh)"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FeeBuyAmount" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-title>Phí vận chuyển (CK: {{orderEditable.FeeWeightDiscount | currency}}%)</v-card-title>
                                        <v-checkbox class="pl-3" v-model="orderEditable.IsUpdateFeeShip" label="Fix đơn giá vận chuyển" v-if="adminRole"></v-checkbox>
                                    </v-flex>
                                    <v-flex lg3 sm3 pt-0>
                                       <h-currency-input :autoEmit="false" v-model="orderEditable.FeeWeight" label="Đơn giá (₫)" :decimal="0" :disabled="!adminRole"></h-currency-input>
                                    </v-flex>
                                    <v-flex lg3 sm3 pt-0 pl-2 pr-2>
                                        <h-currency-input v-model="order.OrderWeight" :decimal="1" :label="order.FeeShipType == 0 ? 'kg' : order.FeeShipType == 1 ? 'm3' : 'kg(quy đổi)'" disabled></h-currency-input>
                                    </v-flex>
                                    <v-flex lg3 sm3 pa-0 pl-2 pr-2>
                                        <h-currency-input v-model="order.FeeWeightAmount" :decimal="0" label="₫" disabled></h-currency-input>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="blue--text" row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phí khác</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input :autoEmit="false" v-model="orderEditable.FeeOther" label="₫" :decimal="0" :disabled="!(order.Status != statusEnum.hoan_thanh && (adminRole || paymentRole))"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <v-text-field v-model="orderEditable.FeeOtherNote" label="Ghi chú" class="input-group--focused"></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="blue--text" row wrap v-if="orderEditable.IsFragileGoods">
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phí đóng gỗ</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FragileFee" label="¥" :decimal="1" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FragileFeeVND" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="blue--text" row wrap v-if="orderEditable.IsCheck">
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phí kiểm hàng</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <!-- <h-currency-input v-model="orderEditable.CheckFee" label="¥" :decimal="1" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole || paymentRole)"></h-currency-input> -->
                                                <h-currency-input v-model="orderEditable.CheckFeeVND" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="blue--text" row wrap v-if="orderEditable.GuaranteeType > 0">
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phí bảo hiểm</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.GuaranteeAmount" label="¥" :decimal="1" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.GuaranteeAmountVND" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <!-- <v-layout class="blue--text" row wrap v-if="orderEditable.IsFast">
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Vận chuyển hỏa tốc</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FastFee" label="¥" :decimal="1" :disabled="!(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole || paymentRole)"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="orderEditable.FastFeeVND" :decimal="0" label="₫" :disabled="orderEditable.FastFee > 0 || !(order.Status == statusEnum.cho_dat_coc || order.Status == statusEnum.cho_bao_gia || adminRole || paymentRole)"></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout> -->
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Phụ phí</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="order.Surcharge" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="purple--text" row wrap>
                                    <v-flex lg3 sm3 pt-0>
                                        <v-card-text>Chiết khấu</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input :autoEmit="false" v-model="orderEditable.DiscountVND" :decimal="0" label="₫" :disabled="!(order.Status != statusEnum.da_thanh_toan && order.Status != statusEnum.hoan_thanh && order.Status != statusEnum.huy_don_hang && adminRole)"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap class="purple--text font-weight-bold" v-if="order.MinDepositRequest >= 0 && orderNotDeposit">
                                    <v-flex lg3 sm3 pt-0 pb-0>
                                        <v-card-text>Đề xuất cọc (chờ duyệt)</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0 pb-0>
                                        <h-currency-input v-model="order.MinDepositRequest" :decimal="0" label="₫" :disabled="true"></h-currency-input>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap class="black--text font-weight-bold">
                                    <v-flex lg3 sm3 pt-0 pb-0>
                                        <v-card-text>Tiền cần cọc</v-card-text>
                                    </v-flex>
                                    <v-flex lg6 sm6 pt-0 pb-0>
                                        <h-currency-input :autoEmit="false" :disabled="!(adminRole || paymentRole) || order.Deposit > 0" v-model="orderEditable.MinDeposit" :decimal="0" label="₫"></h-currency-input>
                                    </v-flex>
                                    <v-flex lg3 sm3 pt-0 pb-0 v-if="orderNotDeposit">
                                        <v-dialog v-model="dialogDeposit" max-width="500px">
                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline">Đề xuất tiền cọc</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container grid-list-md>
                                                        <v-layout wrap>
                                                            <v-flex xs12 sm6 md4>
                                                                <h-currency-input v-model="MinDepositRequest" :decimal="0" label="Số tiền đề xuất"></h-currency-input>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="dialogDeposit = false">Đóng</v-btn>
                                                    <v-btn color="blue darken-1 white--text" @click="orderEditable.MinDepositRequest = MinDepositRequest; MinDepositRequest = null; dialogDeposit = false">Đề xuất</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-btn v-if="!(adminRole || paymentRole) && (orderEditable.MinDepositRequest == null || orderEditable.MinDepositRequest == -1)" color="blue-grey" class="ma-2 white--text" @click="depositRequest">Đề xuất<v-icon right dark>fa-user-check</v-icon></v-btn>
                                        <v-btn v-if="!(adminRole || paymentRole) && orderEditable.MinDepositRequest >= 0" color="blue-grey" class="ma-2 white--text" @click="orderEditable.MinDepositRequest = -1">Hủy đề xuất<v-icon right dark>fa-user-check</v-icon></v-btn>
                                        <v-btn v-if="adminRole && orderEditable.MinDepositRequest >= 0" color="blue-grey" class="ma-2 white--text" @click="orderEditable.MinDeposit = orderEditable.MinDepositRequest; orderEditable.MinDepositRequest = -1">Phê duyệt<v-icon right dark>fa-user-check</v-icon></v-btn>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0 pb-0>
                                        <v-card-text>Tổng cộng</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0 pb-0>
                                        <h-currency-input v-model="order.TotalAmountVND" :decimal="0" label="₫" disabled></h-currency-input>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex lg3 sm3 pt-0 pb-0>
                                        <v-card-text>Đã thanh toán</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9 pt-0 pb-0>
                                        <h-currency-input :autoEmit="false" v-model="orderEditable.Deposit" :decimal="0" label="₫" :disabled="!(order.Status != statusEnum.hoan_thanh && (adminRole || paymentRole))"></h-currency-input>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="red--text font-weight-bold" row wrap>
                                    <v-flex lg3 sm3>
                                        <v-card-text>Cần thanh toán</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9>
                                        <h-currency-input showClass="red--text" v-model="remainAmount" :decimal="0" label="₫" disabled></h-currency-input>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Lich su thanh toan  && (orderRole & 32)-->
            <v-flex sm12 md12 lg12 v-if="orderWidgets.paymentHistory">
                <v-widget title="Lịch sử thanh toán">
                    <div slot="widget-content">
                        <v-data-table  :headers="paymentHistoryHeader" :items="order.PaymentHistory" :mobile-breakpoint="100" :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }" :items-per-page="10">
                            <template  v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.CreatedDate | display_date }}</td>
                                    <td v-bind:style="{ 'color': get_type_color(item.Status, payment_status)}">{{ item.Status | display_value(payment_status) }}</td>
                                    <td>{{ item.Type | payment_method }}</td>
                                    <td class="text-end">{{ item.Amount | currency }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Lich su khieu nai -->
            <v-flex sm12 md12 lg12 v-if="orderWidgets.complain">
                <v-widget title="Lịch sử khiếu nại">
                    <div slot="widget-content">
                        <v-data-table  :headers="paymentComplainHeader" :items="order.OrderComplains" :mobile-breakpoint="100" :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }" :items-per-page="10">
                            <template  v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.ID}}</td>
                                    <td>{{ item.Amount | currency}}</td>
                                    <td>{{ item.ComplainText}}</td>
                                    <td>{{ item.CreatedDate | display_date }}</td>
                                    <td>
                                        <v-chip label small :color="item.Status | complain_type_color" text-color="white">{{ item.Status | complain_type}}</v-chip>
                                    </td>
                                    <td class="text-center">
                                        <v-btn v-if="complainRole" small color="primary" @click="gotoComplainDetail(item.ID)">Chi tiết</v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Lich su thay doi -->
            <v-flex sm12 md12 lg12 v-if="orderWidgets.changeHistory">
                <v-widget title="Lịch sử thay đổi">
                    <div slot="widget-content">
                        <v-data-table  :headers="changedHistoryHeader" :items="order.ChangedHistories" :mobile-breakpoint="100" :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }" :items-per-page="10">
                            <template  v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.CreatedDate | display_date }}</td>
                                    <td>{{ item.Username}}</td>
                                    <td>{{ item.HistoryContent}}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-widget>
            </v-flex>
        </v-layout>
        <v-overlay :value="appLoading" z-index="999999">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</div>
</template>

<script>
import Vue from 'vue';
import VWidget from "@/components/VWidget";
import MessageBox from "@/components/chat/MessageBox";
import OrderTransaction from "@/components/widgets/OrderTransaction";
import OrderFlag from "@/components/widgets/OrderFlag";
import { sendErrorNotice, sendSuccessNotice, makeid } from "@/commons/utils";
import { mapState } from "vuex";
import configs from "@/configs";
import _ from "lodash";
import VueGallery from "vue-gallery";
import {
    request_header,
    payment_type,
    payment_type_color,
    complain_type,
    complain_type_color
} from "@/commons/hpod";
import firebase from 'firebase';

export default {
    filters: {
        payment_type,
        payment_type_color,
        complain_type,
        complain_type_color,
        payment_method: function (value) {
            return value == 1 ? "Trực tiếp" : "Ví điện tử";
        },

    },
    components: {
        VWidget,
        MessageBox,
        gallery: VueGallery,
        'order-flag-menu': OrderFlag,
        'order-transaction': OrderTransaction
    },
    data() {
        return {
            appLoading: false,
            isCheckPackageA: false,
            isCheckPackageB: false,
            imageData: null,
            hideImage: false,
            ignoreUpdate: false,
            productSelectedIndex: -1,
            statusEnum: configs.ORDER_STATUS_ENUM,
            tempOrder: {},
            showCustomerDetail: false,
            MinDepositRequest: null,
            dialogProduct: false,
            dialogAppendProduct: false,
            dialogDeposit: false,
            message: "",
            timeout: 100000,
            events: [],
            input: null,
            nonce: 0,
            search: "",
            imgIndex: null,
            imageName: "",
            imageFile: "",
            payment_status: configs.PAYMENT_STATUS_LIST,
            editedPro: {
                quantity: 0,
                price: 0,
                comment: ''
            },
            orderEditable: {
                OrderID: 0,
                SalerID: 0,
                CustomerServiceID: 0,
                BuyerID: 0,
                AmountCNY: 0,
                AmountVND: 0,
                ExchangeRate: 0,
                ExchangeRevenue: 0,
                TotalRealAmountCNY: 0,
                FeeShipCN: 0,
                FeeBuy: 0,
                FeeBuyDiscount: 0,
                FeeWeight: 0,
                FeeWeightDiscount: 0,
                FeeOther: 0,
                FeeOtherNote: '',
                ChinaStoreID: 0,
                ReceivePlaceID: 0,
                IsFragileGoods: false,
                FragileFee: 0,
                FragileFeeVND: 0,
                IsCheck: false,
                CheckFeeType: 0,
                CheckFee: 0,
                CheckFeeVND: 0,
                IsFast: false,
                FastFee: 0,
                FastFeeVND: 0,
                GuaranteeType: 0,
                Deposit: 0,
                MinDeposit: 0,
                DiscountVND: 0,
                MinDepositRequest: null,
                WeChatId: null,
                IsUpdateFeeShip: 0,
                ComplainImages: ''
            },
            totalRealAmount: 0,
            feeShipCNY: 0,
            totalRealAmountCNY: 0,
            paymentHistoryHeader: [{
                    text: "Ngày thanh toán",
                    value: "CreatedDate",
                    sortable: false
                },
                {
                    text: "Loại thanh toán",
                    value: "Status",
                    sortable: false
                },
                {
                    text: "Hình thức thanh toán",
                    value: "Type",
                    sortable: false
                },
                {
                    text: "Số tiền",
                    value: "Amount",
                    sortable: false,
                    align: "end"
                }
            ],
            changedHistoryHeader: [{
                    text: "Ngày thay đổi",
                    value: "CreatedDate",
                    sortable: false
                },
                {
                    text: "Người thay đổi",
                    value: "Username",
                    sortable: false
                },
                {
                    text: "Nội dung",
                    value: "HistoryContent",
                    sortable: false
                }
            ],
            paymentComplainHeader: [{
                    text: "ID",
                    value: "ID",
                    sortable: false
                },
                {
                    text: "Số tiền",
                    value: "Amount",
                    sortable: false,
                    align: "end"
                },
                {
                    text: "Nội dung",
                    value: "ComplainText",
                    sortable: false
                },
                {
                    text: "Ngày tạo",
                    value: "CreatedDate",
                    sortable: false
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    sortable: false
                },
                {
                    text: "Thao tác",
                    align: "center",
                    value: "",
                    sortable: false
                }
            ],
            productHeader: [{
                    text: "TT",
                    value: "index",
                    sortable: false
                },
                {
                    text: "ID",
                    value: "ID",
                    sortable: false
                },
                {
                    text: "Ảnh",
                    value: "image_origin",
                    sortable: false
                },
                {
                    text: "Thuộc tính",
                    value: "property",
                    sortable: false
                },
                {
                    text: "Số lượng",
                    value: "quantity",
                    align: "end",
                    sortable: false
                },
                {
                    text: "Giá (¥)",
                    value: "price_promotion",
                    align: "end",
                    sortable: false
                },
                {
                    text: "Thành tiền (¥)",
                    value: "total",
                    align: "end",
                    sortable: false
                },
                {
                    text: "",
                    align: "center",
                    sortable: false
                }
            ],
            newProduct: {
                Link: '',
                Image: '',
                Property: '',
                Price: 0,
                Quantity: 1
            },
            newProducts: []
        };
    },
    computed: {
        orderId() {
            return Number(this.$route.params.Pid);
        },
        orderStatus() {
            return configs.ORDER_STATUS;
        },
        remainAmount() {
            return this.order.TotalAmountVND - this.order.Deposit;
        },
        totalProduct() {
            return _.sumBy(this.products, 'quantity');
        },
        customerView() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Manager) == configs.ROLE_TYPE.Manager);
        },
        canReorder() {
            return this.order.Status == configs.ORDER_STATUS_ENUM.huy;
        },
        orderReady() {
            return this.order.Status == configs.ORDER_STATUS_ENUM.da_dat_coc;
        },
        orderSigned() {
            return this.order.Status == configs.ORDER_STATUS_ENUM.da_nhap_kho_tq;
        },
        orderPaymentReady() {
            return this.order.Status == configs.ORDER_STATUS_ENUM.da_nhap_kho_phat;
        },
        orderNotDeposit() {
            return this.order.Status == configs.ORDER_STATUS_ENUM.cho_dat_coc;
        },
        orderLocked() {
            return this.order.Status == configs.ORDER_STATUS_ENUM.huy || this.order.Status == configs.ORDER_STATUS_ENUM.khieu_nai;
        },
        confirmPrice() {
            return this.order.Status == configs.ORDER_STATUS_ENUM.cho_bao_gia;
        },
        orderReadonly() {
            return this.order.Status == configs.ORDER_STATUS_ENUM.huy || this.order.Status == configs.ORDER_STATUS_ENUM.hoan_thanh || this.order.Status == configs.ORDER_STATUS_ENUM.khieu_nai;
        },
        orderRole() {
            return this.$store.state.authentication.role.Orders;
        },
        orderEditRole() {
            return this.$store.state.authentication.role.OrderEditable;
        },
        productEditable() {
            return this.order.Status == configs.ORDER_STATUS_ENUM.da_dat_coc || this.order.Status == configs.ORDER_STATUS_ENUM.cho_dat_coc || this.order.Status == configs.ORDER_STATUS_ENUM.cho_bao_gia  || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment);
        },
        complainRole() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService));
        },
        realAmountRole() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment);
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        purchaseRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
        },
        paymentRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
        csRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService;
        },
        internalRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.InteralControl) == configs.ROLE_TYPE.InteralControl;
        },
        ...mapState({
            order: state => state.main_order.selectedOrder,
            customer: state => state.main_order.selectedOrder.Customer,
            products: state => state.main_order.selectedOrder.Products,
            staffs: state => state.commons.all_users,
            p_staffs: state =>
                _.filter(state.commons.all_users, function(o) {
                return (o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
            }),
            cs_staffs: state =>
                _.filter(state.commons.all_users, function(o) {
                return (o.RoleType & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService;
            }),
            vipLevels: state => state.customerLevel.all.data,
            warehouses: state => state.commons.stores,
            chinaStores: state => state.commons.chinaStores,
            allFlags: state => state.orderFlag.all.data,
            guarantees: state => {
                let tmp = [{PackageName: 'Không sử dụng', ID: 0}];
                if(state.feeGuarantee.all.data) {
                state.feeGuarantee.all.data.forEach(element => {
                    tmp.push(element);
                });
                }
                return tmp;
            }
        }),
        ...mapState("main_order", {
            loading: "loading",
        }),
        ...mapState("commons", {
            orderWidgets: "orderWidgetSettings",
        }),
        ...mapState("orderTransaction", {
            weightEdited: "edited",
        }),
        images() {
            var listOfImg = this.order.ComplainImages
                ? this.order.ComplainImages.split(";")
                : [];
            for (let i = 0; i < listOfImg.length; i++) {
                listOfImg[i] = `${configs.SYSTEM_CONFIG.firebase_storage}/images%2Fcomplain%2F${listOfImg[i]}?alt=media`;
            }
            return listOfImg;
        },
    },
    watch: {
        orderId() {
            this.refreshData();
        },
        weightEdited() {
            if (this.weightEdited) {
                this.refreshData();
            }
        },
        order() {
            this.ignoreUpdate = true;
            this.orderEditable = this.getOrderEditable();
            this.totalRealAmount = this.order.TotalRealAmount;
            this.feeShipCNY = this.order.FeeShipCN;
            this.totalRealAmountCNY = this.order.TotalRealAmountCNY;
            setTimeout(() => {
                this.ignoreUpdate = false;
            }, 1000);
        },
        orderEditable: {
            handler: function (newVal, oldVal) {
                if(!this.ignoreUpdate) {
                    this.updateOrderDetail(newVal, oldVal);
                }
            },
            deep: true,
            immediate: true
        },
        isCheckPackageA() {
            if(this.isCheckPackageA) {
                this.isCheckPackageB = false;
                this.orderEditable.IsCheck = 1;
                this.orderEditable.CheckFeeType = 1;
            }
            if(!this.isCheckPackageA && !this.isCheckPackageB) {
                this.orderEditable.IsCheck = 0;
                this.orderEditable.CheckFeeType = 0;
            }
        },
        isCheckPackageB() {
            if(this.isCheckPackageB) {
                this.isCheckPackageA = false;
                this.orderEditable.IsCheck = 1;
                this.orderEditable.CheckFeeType = 2;
            }
            if(!this.isCheckPackageA && !this.isCheckPackageB) {
                this.orderEditable.IsCheck = 0;
                this.orderEditable.CheckFeeType = 0;
            }
        }
    },
    methods: {
        getOrderEditable() {
            this.isCheckPackageA = this.order.IsCheck && this.order.CheckFeeType == 1;
            this.isCheckPackageB = this.order.IsCheck && this.order.CheckFeeType == 2;
            return {
                OrderID: this.order.ID,
                SalerID: this.order.SalerID,
                CustomerServiceID: this.order.CustomerServiceID,
                BuyerID: this.order.BuyerID,
                TotalRealAmountCNY: this.order.TotalRealAmountCNY,
                FeeShipCN: this.order.FeeShipCN,
                FeeShipCNVND: this.order.FeeShipCNVND,
                ExchangeRate: this.order.ExchangeRate,
                ExchangeRevenue: this.order.ExchangeRevenue,
                FeeBuy: this.order.FeeBuy,
                FeeBuyDiscount: this.order.FeeBuyDiscount,
                FeeBuyAmount: this.order.FeeBuyAmount,
                FeeWeight: this.order.FeeWeight,
                FeeWeightDiscount: this.order.FeeWeightDiscount,
                FeeWeightAmount: this.order.FeeWeightAmount,
                FeeOther: this.order.FeeOther,
                FeeOtherNote: this.order.FeeOtherNote,
                ChinaStoreID: this.order.ChinaStoreID,
                ReceivePlaceID: this.order.ReceivePlaceID,
                IsFragileGoods: this.order.IsFragileGoods,
                FragileFee: this.order.FragileFee,
                FragileFeeVND: this.order.FragileFeeVND,
                IsCheck: this.order.IsCheck,
                CheckFeeType: this.order.CheckFeeType,
                CheckFee: this.order.CheckFee,
                CheckFeeVND: this.order.CheckFeeVND,
                IsFast: this.order.IsFast,
                FastFee: this.order.FastFee,
                FastFeeVND: this.order.FastFeeVND,
                Deposit: this.order.Deposit,
                DiscountVND: this.order.DiscountVND,
                GuaranteeType: this.order.GuaranteeType,
                MinDeposit: this.order.MinDeposit,
                MinDepositRequest: this.order.MinDepositRequest,
                WeChatId: this.order.WeChatId,
                IsUpdateFeeShip: this.order.IsUpdateFeeShip,
                ComplainImages: this.order.ComplainImages
            }            
        },
        closePanel() {
            this.$router.push({
                name: "OrderList"
            });
        },
        getUserName(id) {
            const obj = this.$store.state.commons.all_users.find(
                obj => obj.ID === id
            );
            return obj ? obj.Username : "";
        },
        getVipValue(id) {
            const item = _.find(this.vipLevels, {'ID': id});
            return item ? item.VIPName : '';
        },
        closeSnackbar() {
            Store.commit("main_order/setSnackbar", {
                snackbar: false
            });
            Store.commit("main_order/setNotice", {
                notice: ""
            });
        },
        refreshData() {
            this.orderEditable.OrderID = 0;
            this.$store.dispatch("commons/getAllUsers");
            this.$store.dispatch("orderFlag/getList", {'OrderID': this.orderId, 'OrderType': configs.ORDER_TYPE.order});
            this.$store.dispatch("feeGuarantee/getList");
            this.$store.dispatch("customerLevel/getCustomerLevelList");
            this.$store.dispatch("main_order/getOrderDetail", this.orderId);
        },
        manualUpdateDetail() {
            this.orderEditable.FeeShipCN = this.feeShipCNY;
            this.orderEditable.TotalRealAmountCNY = this.totalRealAmountCNY;
        },
        updateOrderDetail: _.debounce(function (newVal, oldVal) {
            if (oldVal && oldVal.OrderID !== 0) {
                if (newVal.TotalRealAmountCNY !== 0) {
                    newVal.TotalRealAmount =
                        newVal.TotalRealAmountCNY * this.order.ExchangeRate;
                    this.totalRealAmount = newVal.TotalRealAmount;
                }
                // Validate data
                if(newVal.TotalRealAmountCNY > (this.order.AmountCNY + this.order.FeeShipCN)) {
                    sendErrorNotice("Tiền mua thật không được lớn hơn tổng giá sản phẩm!");
                    this.totalRealAmountCNY = 0;
                    return;
                }
                if(!_.isEqual(newVal, this.tempOrder)){
                    if(this.order.MinDeposit != this.orderEditable.MinDeposit) {
                        newVal.IgnoreCalc = 1;
                    }
                    this.tempOrder = _.cloneDeep(newVal);
                    if(newVal.Deposit == this.order.Deposit) {
                        delete newVal.Deposit;
                    }
                    this.$store.dispatch("main_order/updateOrderDetail", newVal);
                }
            }
        }, 1000),
        depositRequest() {
            this.dialogDeposit = true;
        },
        editProduct(item) {
            this.editedPro = {...item};
            this.dialogProduct = true;
        },
        appendProductShow() {
            this.dialogAppendProduct = true;
        },
        removeProduct(item) {
            window.getApp.$emit("REQUEST_DELETE_DATA", {id: item.ID, type: 'Product'} , `Bạn thực sự muốn xoá sản phẩm ?`);
        },
        removeOrder(item) {
            window.getApp.$emit("REQUEST_DELETE_DATA", {id: item.ID, type: 'Order'}, `Bạn thực sự muốn xoá đơn hàng ?`);
        },
        closeProductDialog() {
            this.dialogProduct = false;
        },
        saveProduct(item) {
            if(!item) {
                item = this.editedPro;
            }
            item.OrderID = this.orderId;
            this.$store.dispatch("main_order/updateProduct", item).then(() => {
                this.refreshData();
                this.dialogProduct = false;
            });
        },
        gotoComplainDetail(id) {
            let routeData = this.$router.resolve({
                path: `/khieu-nai/${id}`,
                params: {
                Pid: id,
                title: `Chi tiết khiếu nại (#${id})`
                }
            });
            window.open(routeData.href, "_blank");
        },
        get_type_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        ReOrder() {
            if(this.order.Status == configs.ORDER_STATUS_ENUM.huy) {
                this.$store.dispatch("main_order/updateOrderDetail", {OrderID: this.order.ID, Status: configs.ORDER_STATUS_ENUM.cho_dat_coc});
            }
            else {
                sendErrorNotice("Không thể lên lại đơn hàng!");
            }
        },
        CancelOrder() {
            if(this.order.Status == configs.ORDER_STATUS_ENUM.da_dat_coc || this.order.Status == configs.ORDER_STATUS_ENUM.cho_dat_coc || this.adminRole) {
                this.$store.dispatch("main_order/updateOrderDetail", {OrderID: this.order.ID, Status: configs.ORDER_STATUS_ENUM.huy});
            }
            else {
                sendErrorNotice("Không thể hủy đơn hàng!");
            }
        },
        ComplainOrder(complain) {
            if(this.order.Status == configs.ORDER_STATUS_ENUM.da_nhap_kho_phat && complain) {
                this.$store.dispatch("main_order/updateOrderDetail", {OrderID: this.order.ID, Status: configs.ORDER_STATUS_ENUM.khieu_nai_shop});
            }
            else if(this.order.Status == configs.ORDER_STATUS_ENUM.khieu_nai_shop && !complain) {
                this.$store.dispatch("main_order/updateOrderDetail", {OrderID: this.order.ID, Status: configs.ORDER_STATUS_ENUM.da_nhap_kho_phat});
            }
            else {
                sendErrorNotice("Không thể cập nhật đơn hàng!");
            }
        },
        WaitPayOrder() {
            if(this.order.Status == configs.ORDER_STATUS_ENUM.cho_bao_gia) {
                this.$store.dispatch("main_order/updateOrderDetail", {OrderID: this.order.ID, Status: configs.ORDER_STATUS_ENUM.cho_dat_coc});
            }
            else {
                sendErrorNotice("Không thể chuyển trạng thái đơn hàng!");
            }
        },
        DepositOrder() {
            if(this.order.Status == configs.ORDER_STATUS_ENUM.cho_dat_coc) {
                this.$store.dispatch("main_order/updateOrderDetail", {OrderID: this.order.ID, UID: this.order.UID, Status: configs.ORDER_STATUS_ENUM.da_dat_coc, Deposit: this.order.MinDeposit, ActionType: 'DepositOrder'});
            }
            else {
                sendErrorNotice("Không thể chuyển trạng thái đơn hàng!");
            }
        },
        openCustomerDetail: function() {
            this.showCustomerDetail = !this.showCustomerDetail;
        },
        gotoCustomerDetail: function(id) {
            let routeData = this.$router.resolve({
                name: "CustomerDetail",
                params: {
                Pid: id,
                title: `Khách hàng (#${id})`
                }
            });
            window.open(routeData.href, "_blank");
        },
        appendProduct() {
            this.newProduct.OrderID = this.order.ID;
            this.newProduct.UID = this.order.UID;
            this.newProducts.push(this.newProduct);
            this.newProduct = {
                Link: '',
                Image: '',
                Property: '',
                Price: 0,
                Quantity: 1
            }
        },
        createNewOrder() {
            if(this.newProducts.length == 0) {
                sendErrorNotice("Hãy nhập danh sách sản phẩm!");
                return;
            }
            this.$store.dispatch("main_order/createNewLink", {'data': {'Products': this.newProducts}, 'id': this.order.ID });
            this.newProducts = [];
            this.dialogAppendProduct = false;
            sendSuccessNotice('Thêm mới link sản phẩm thành công.');
        },
        chooseImage(item) {
            this.newProduct.Image = '';
            this.$refs.fileInput.click();
        },
        previewImage(event) {
            this.imageData = event.target.files[0];
            this.$refs.fileInput.value = null;
            this.onUpload()
        },
        onUpload() {
            const arrFileType = this.imageData.name.split('.');
            const storageRef=firebase.storage().ref(`images/${makeid(30)}.${arrFileType[arrFileType.length - 1]}`).put(this.imageData);
            storageRef.on(`state_changed`, snapshot => {
                    
                },
                error => {
                sendErrorNotice('File ảnh chỉ được tối đa 3Mb!');
                },
                () => {
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        this.newProduct.Image =url;
                        this.hideImage = true;
                        this.$nextTick(() => this.hideImage = false);
                    });
                }
            );
        },
        getImages(index) {
            return this.newProducts[index].Image;
        },
        pickFile() {
            this.$refs.image.click();
        },
        onFilePicked(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {
                this.imageName = files[0].name;
                if (this.imageName.lastIndexOf(".") <= 0) {
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                this.imageFile = files[0]; // this is an image file that can be sent to server...
                });
            } else {
                this.imageName = "";
                this.imageFile = "";
            }
        },
        // 画像アップロード処理
        uploadImage: function() {
            // ストレージオブジェクト作成
            var storageRef = firebase.storage().ref();
            // ファイルのパスを設定
            var mountainsRef = storageRef.child(`images/complain/${this.imageName}`);
            // ファイルを適用してファイルアップロード開始
            mountainsRef.put(this.imageFile).then(snapshot => {
                snapshot.ref.getDownloadURL().then(downloadURL => {
                this.imageUrl = downloadURL;
                var listOfImg = this.order.ComplainImages ? this.order.ComplainImages.split(";") : [];
                listOfImg.push(this.imageName)
                this.orderEditable.ComplainImages = listOfImg.join(';')
                });
            }, error => {
                sendErrorNotice('Có lỗi upload ảnh (lưu ý ảnh tối đa 3Mb)')
            });
        },
        removeComplainImage(index) {
            var listOfImg = this.order.ComplainImages ? this.order.ComplainImages.split(";") : [];
            if(listOfImg.length > 0 && index < listOfImg.length) {
                listOfImg.splice(index, 1);
                this.orderEditable.ComplainImages = listOfImg.join(';')
            }
        },
        gotoOfferLink(product_id, site, link_origin) {
            let platform = 0;
            this.appLoading = true;
            if(product_id && site) {
                if (site == '1688') {
                    platform = 1;
                }

                Vue.http.get(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/admin/api/main_order/offerlink/?platform=${platform}&product_id=${product_id}`, {
                    headers: request_header(this.$store.state.authentication.token)
                }).then(function (res) {
                    setTimeout(() => {
                        if(res.data.data && res.data.data.commission_url != '') {
                            window.open(res.data.data.commission_url, "_blank");
                        }
                        else {
                            sendErrorNotice("Không tìm thấy link chiết khấu!");
                            window.open(link_origin, "_blank");
                        }
                    }, 100);
                });
                this.appLoading = false;
            }
            else {
                this.appLoading = false;
                sendErrorNotice(`Không có ck cho sản phẩm product_id=${product_id}, site=${site}!`);
                window.open(link_origin, "_blank");
            }
        }
    },
    mounted() {
        this.$store.dispatch("commons/getAllStores");
        this.$store.dispatch("commons/getAllChinaStores");
        this.refreshData();
        this.$root.$on("CONFIRMED_REMOVE_DATA", item => {
            if(item.type == 'Product') {
                this.$store.dispatch("main_order/removeProduct", {id: item.id, OrderID: this.orderId}).then(() => {
                    this.refreshData();
                });
            }
            else {
                this.$store.dispatch("main_order/removeOrder", {id: item.id}).then(() => {
                    this.$router.push({ name: "OrderList" });
                });
            }
        });
        this.$root.$on("REQUEST_REFRESH_DATA", this.refreshData);
    },
    beforeDestroy() {
        this.$root.$off("CONFIRMED_REMOVE_DATA");
        this.$root.$off("REQUEST_REFRESH_DATA", this.refreshData);
    }
};
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.product-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.v-list--two-line .v-list__tile {
    height: auto !important;
    padding: 5px 0 5px 0;
}

.theme--light.red--text.v-input--is-disabled input {
    color: #f44336 !important;
    caret-color: #f44336 !important;
}

.image-product {
    width: 64px;
}

.theme--light.v-data-table tbody tr td .text-red,
.text-red .v-select__selection,
.text-red .v-icon.v-icon {
    color: #a77800!important;
}
.product-highlight-row {
    background-color: bisque;
}
.image-container {
  width: 80%;
  padding: 15px 15px 15px 15px;
  margin: 20px 20px 20px 20px;
  overflow: visible;
  position: relative;
}

.image-x {
    position: absolute;
    background: red;
    color: white;
    top: -10px;
    right: -10px;
}
.image-zoom {
    position: absolute;
    background: green;
    color: white;
    top: 40px;
    right: -10px;
}
</style>
