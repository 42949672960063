<template>
<v-menu v-model="menuWidget" :close-on-content-click="false" :nudge-width="200" offset-x>
    <template v-slot:activator="{ on }">
        <v-btn v-on="on"><v-icon medium>fa-tag</v-icon> <v-icon right dark>arrow_drop_down</v-icon></v-btn>
    </template>
    <v-card>
        <v-list>
            <template v-for="item in allFlags" >
                <v-list-item v-bind:key="item.ID">
                    <v-list-item-action>
                        <!-- <v-switch v-if="item.AutoFlag == 0 && item.IsStandby" v-model="item.Checked" :color="item.FlagColor" @change="updateFlag(item)"></v-switch>
                        <v-switch v-else v-model="item.Checked" :color="item.FlagColor" @change="updateFlag(item)" :disabled="purchaseRole && !item.Checked"></v-switch> -->
                        <v-switch v-model="item.Checked" :color="item.FlagColor" @change="updateFlag(item)" :disabled="!adminRole && !purchaseRole && !csRole && !storeVNRole && item.Checked"></v-switch>
                    </v-list-item-action>
                    <v-list-item-title>{{item.FlagName}}</v-list-item-title>
                    <v-list-item-avatar>
                        <v-icon medium :color="item.FlagColor">fa-tag</v-icon> 
                    </v-list-item-avatar>
                </v-list-item>
            </template>
        </v-list>
    </v-card>
</v-menu>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
export default {
    name: 'order-flag-menu',
    props: {
        orderID: {
            type: Number,
            default: 0
        },
        orderType: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            menuWidget: false,
        }
    },
    methods: {
        updateFlag: _.debounce(
            function(item) {
                if(item.Checked && item.ID == null) {
                    this.$store.dispatch("orderFlag/setDetail", {
                        data: {
                            OrderID: this.orderID,
                            OrderType: this.orderType,
                            FlagID: item.FlagID
                        },
                        id: 0
                    });
                }
                else if(!item.Checked && item.ID > 0) {
                    this.$store.dispatch("orderFlag/removeDetail", {id: item.ID, orderID: this.orderID, orderType: this.orderType});
                }
            },
            300,
            {
                leading: true,
                trailing: false
            }
        )
    },
    computed: {
        ...mapState({
            allFlags: state => state.orderFlag.all.data,
        }),
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        purchaseRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
        },
        csRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService;
        },
        storeVNRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreVN) == configs.ROLE_TYPE.StoreVN;
        }
    }
}
</script>
